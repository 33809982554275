.bckground {
    width: 100%;
    height: 100vh;
    position: relative;
}

.landing-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;

}

.my-name {
    color: rgba(8, 197, 230);
}

.bckground::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.home-img {
    height: 100%;
    width: 100%;
}

.home-img .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: left;
}

.home-img .content h1 {
    font-size: 3rem;
    padding: 0.1rem 0 1.5rem;
}

.home-img .content p {
    font-size: 1.4rem;
    font-weight: 200;
    
}

.content .btn {
    margin: 1rem 0.2rem;
}

@media screen and (max-width: 640px)  {
    
    .home-img .content h1 {
        font-size: 2rem;
    }
    
    .home-img .content p {
        font-size: 1.4rem;
    }
}