.about {
    width: 100%;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.about .left {
    text-align: left;
    margin: auto;
    padding: 1rem;
    max-width: 450px;
}

.about .left p{
    margin: 1.2rem 0;
}

.about .right {
    max-width: 600px;
}

img {
    width: 20rem;
}

.right .img-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 640px) {
    .img {
        display: none;
    }

    .about .left {
        text-align: center;
        margin: auto;
        padding: 1rem;
        max-width: 450px;
    }
    
}





