@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
}

body {
  background:black ;
}

h1, h3, p, a{
  color: white;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(8, 197, 230);
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  background: transparent;
  color: #fff;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
}

.header-bg {
    background-color: rgb(0, 0, 0, 0.85);
    transition: 0.5s;
}

.nav-menu {
    display: flex;
}

.nav-menu li {
    padding: 0 1rem;
}

.nav-menu li a{
    font-size: 1.2rem;
    font-weight: 500;
}

.nav-menu li a:hover {
    color: rgba(8, 197, 230);
}

.hamburger {
    display: none;
}

@media screen and (max-width: 1040px) {
    .nav-menu {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.9);
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -3;
        transition: 0.3s;
    }

    .nav-menu.active {
        left: 0;
    }
    
    .nav-menu li {
        padding: 1rem 0;
    }

    .nav-menu li a {
        font-size: 2rem;
    }

    .hamburger {
        display: initial;
    }
    
}
.bckground {
    width: 100%;
    height: 100vh;
    position: relative;
}

.landing-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;

}

.my-name {
    color: rgba(8, 197, 230);
}

.bckground::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.home-img {
    height: 100%;
    width: 100%;
}

.home-img .content {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: left;
}

.home-img .content h1 {
    font-size: 3rem;
    padding: 0.1rem 0 1.5rem;
}

.home-img .content p {
    font-size: 1.4rem;
    font-weight: 200;
    
}

.content .btn {
    margin: 1rem 0.2rem;
}

@media screen and (max-width: 640px)  {
    
    .home-img .content h1 {
        font-size: 2rem;
    }
    
    .home-img .content p {
        font-size: 1.4rem;
    }
}
.footer {
    width: 100%;
    padding: 6rem 0;
    background-color: rgb(19, 19, 19, 0.8);
}

.footer-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
}

.left {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.location {
    display: flex;
    margin-bottom: 0.8rem;
}

.location p {
    line-height: 30px;
}

.right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.social {
    margin-top: 1rem;
}

h4 {
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
    color: #fff;
}

p {
    font-size: 1.2rem;
}

.socials {
    margin-top: 1rem;
}

@media screen and (max-width: 640px) {
    .footer-container {
        grid-template-columns: 1fr;
    }
}
.bckground-img {
    width: 100%;
    background: rgba(0, 0, 0, 0.5) ;
    height: 60vh;
    position: relative;
}

.bckground-img::before {
    content: "";
    background: url(https://images.unsplash.com/photo-1484417894907-623942c8ee29?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80);
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bckground-img h1 {
    font-size: 2.4rem;
}

.bckground-img p {
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width :640px) {
    .bckground-img h1 {
        font-size: 2rem;
    }
}
.project-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
    padding-top: 5rem;
}

.project-card {
    background: #1a1919;
    padding: 1.2rem 1rem ;
    text-align: center;
}

.project-card img {
    width: 100%;
}

.project-title {
    color: #fff;
    padding: 1rem 0;
}

.pro-details p {
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: justify;
}

.pro-btns {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
}

.pro-btns .btn {
    padding: 0.5rem 1rem;
}


@media screen and (max-width: 740px) {
    .project-container {
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }

    
}
.about {
    width: 100%;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.about .left {
    text-align: left;
    margin: auto;
    padding: 1rem;
    max-width: 450px;
}

.about .left p{
    margin: 1.2rem 0;
}

.about .right {
    max-width: 600px;
}

img {
    width: 20rem;
}

.right .img-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 640px) {
    .img {
        display: none;
    }

    .about .left {
        text-align: center;
        margin: auto;
        padding: 1rem;
        max-width: 450px;
    }
    
}






label {
    color: #fff;
    margin-bottom: 0.5rem;
}

form {
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 600px;
}

form input, form textarea{
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    color: #f4f4f4;
}
